import { axiosClient } from './axiosClient';
import { TOptionsQuery } from '../types/common';
import queryString from 'querystring';
import { TEmployeeManagement } from 'types/clientManagement/clientManagement';

export const employeeApi = {
  generateEmployeeId: () => {
    const url = '/employee/generate-id';
    return axiosClient.get(`${url}`);
  },
  getListEmployee: (params: TOptionsQuery<TEmployeeManagement>) => {
    const url = '/employee';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  registerEmployee: (data: TEmployeeManagement) => {
    const url = '/employee';
    return axiosClient.post(url, data);
  },
  getInfoEmployee: (id: string) => {
    const url = `/employee/${id}`;
    return axiosClient.get(url);
  },
  updateEmployee: (params: { id: string; data: TEmployeeManagement }) => {
    const url = `/employee/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  deleteEmployee: (id: string) => {
    const url = `/employee/${id}`;
    return axiosClient.delete(url);
  },
  inActiveEmployee: (id: string) => {
    const url = `/admin/user/update/${id}/inactive`;
    return axiosClient.patch(url);
  },
  activeEmployee: (id: string) => {
    const url = `/admin/user/update/${id}/active`;
    return axiosClient.patch(url);
  },
  uploadFileCsv: (file: File) => {
    const url = `employee/uploadCsv`;
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  updateListEmployeeFromFileCsv: (data: { file: File; clientId: string }) => {
    const url = `/employee/bulkEmployee`;
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('clientId', data.clientId);
    return axiosClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  validateDuplicatedEmployees: (data: { file: File; clientId: string }) => {
    const url = `/employee/validate-duplicated-employees`;
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('clientId', data.clientId);
    return axiosClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  downloadCsv: (clientId: string) => {
    const url = `/employee/download/csv/${clientId}`;
    return axiosClient.get(url, { responseType: 'blob' });
  },
  resetPW: (employeeId) => {
    const url = `/auth/user/reset-password/${employeeId}`;
    return axiosClient.put(url);
  },
};
